import JustValidate from "just-validate";
import intlTelInput from "intl-tel-input";

import { demo, subscribe } from "./modal";

export const validateForms = (
  selector,
  rules,
  onSuccessSend,
  onFailSend,
  options = null,
  translation = null,
  locale = null
) => {
  const form = document?.querySelector(selector);
  const formBtn = form?.querySelector("button");
  const telSelector = form?.querySelector('input[type="tel"]');

  if (!form) {
    console.error("Нет такого селектора!");
    return false;
  }

  if (!rules) {
    console.error("Вы не передали правила валидации!");
    return false;
  }

  if (telSelector) {
    intlTelInput(telSelector, {
      initialCountry: "ua",
      nationalMode: false,
      autoHideDialCode: false,
      autoPlaceholder: "polite",
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.0/js/utils.js",
    });
  }

  const validation = new JustValidate(selector, options, translation);

  if (translation && locale) {
    validation.setCurrentLocale(locale);
  }

  for (let item of rules) {
    validation.addField(item.ruleSelector, item.rules);
  }

  validation.onSuccess(async (event) => {
    let formData = new FormData(event.target);
    formData = Object.fromEntries(formData);
    formBtn.disabled = true;

    // search and remove old error messages
    const errorMessages = form.querySelectorAll(".validate__error");
    errorMessages.forEach((errNode) => errNode.remove());

    for (let key in formData) {
      if (formData[key] === "on") {
        formData[key] = true;
      } else if (formData[key] === "off") {
        formData[key] = false;
      }
    }

    if (selector === ".modal__join") {
      const response = await fetch("https://ucliq.com/api/subscribe", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      formBtn.disabled = false;

      if (response.status < 199 || response.status > 300) {
        const errorMessage = form.querySelector(".modal__form-status--error");

        errorMessage.style.display = "block";

        // get answer from server
        const errorResponseObj = await response.json();

        const errors = errorResponseObj.violations.reduce((acc, err) => {
          if (acc.hasOwnProperty(err.propertyPath)) {
            acc[err.propertyPath] = [...acc[err.propertyPath], err.message];
          } else {
            acc[err.propertyPath] = [err.message];
          }

          return acc;
        }, {});

        for (const key in errors) {
          const input = form.querySelector(`[name='${key}']`);
          input.style.border = "1px solid rgb(184, 17, 17)";
          errors[key].forEach((err) => {
            input.insertAdjacentHTML(
              "afterend",
              `<div
                    class="validate__error"
                  >
                    ${err}
                  </div>`
            );
          });
        }

        setTimeout(() => {
          errorMessage.style.display = "";
        }, 5000);
        console.error("Ошибка при отправке!");
        // onFailSend();
        return false;
      } else {
        const btnHide = form.querySelector(".btn--hide");
        btnHide.click();
        const subscribeBtns = document.querySelectorAll(".modal-join");
        const modalSpam = document.querySelector(".modal--spam");
        subscribeBtns.forEach((btn) => {
          btn.removeEventListener("click", subscribe);
          btn.addEventListener("click", () => {
            modalSpam.classList.add("show");
          });
        });
      }
      formBtn.disabled = false;
      console.log("Отправлено");
      event.target.reset();
    } else if (selector === ".modal__register") {
      if (!formData.hasOwnProperty("newsletterSubscriber")) {
        formData.newsletterSubscriber = false;
      }

      const response = await fetch("https://ucliq.com/api/demo", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      formBtn.disabled = false;

      if (response.status < 199 || response.status > 300) {
        const errorMessage = form.querySelector(".modal__form-status--error");

        errorMessage.style.display = "block";

        // get answer from server
        const errorResponseObj = await response.json();

        const errors = errorResponseObj.violations.reduce((acc, err) => {
          if (acc.hasOwnProperty(err.propertyPath)) {
            acc[err.propertyPath] = [...acc[err.propertyPath], err.message];
          } else {
            acc[err.propertyPath] = [err.message];
          }

          return acc;
        }, {});

        for (const key in errors) {
          const input = form.querySelector(`[name='${key}']`);
          input.style.border = "1px solid rgb(184, 17, 17)";
          errors[key].forEach((err) => {
            input.insertAdjacentHTML(
              "afterend",
              `<div
                    class="validate__error"
                  >
                    ${err}
                  </div>`
            );
          });
        }

        setTimeout(() => {
          errorMessage.style.display = "";
        }, 5000);
        console.error("Ошибка при отправке!");
        // onFailSend();
        return false;
      } else {
        const btnHide = form.querySelector(".btn--hide");
        btnHide.click();
        const demoBtn = document.querySelector(".modal-register");
        demoBtn.removeEventListener("click", demo);
        demoBtn.addEventListener("click", () => {
          const modalThanks = document.querySelector(".modal--thanks");
          modalThanks.classList.add("show");
        });
      }

      console.log("Отправлено");
      event.target.reset();
    } else if (selector === ".contact__form") {
      const response = await fetch("https://ucliq.com/api/feedback", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      formBtn.disabled = false;

      if (response.status < 199 || response.status > 300) {
        const errorMessage = form.querySelector(".modal__form-status--error");

        errorMessage.style.display = "block";

        // get answer from server
        const errorResponseObj = await response.json();

        const errors = errorResponseObj.violations.reduce((acc, err) => {
          if (acc.hasOwnProperty(err.propertyPath)) {
            acc[err.propertyPath] = [...acc[err.propertyPath], err.message];
          } else {
            acc[err.propertyPath] = [err.message];
          }

          return acc;
        }, {});

        for (const key in errors) {
          const input = form.querySelector(`[name='${key}']`);
          input.style.border = "1px solid rgb(184, 17, 17)";
          errors[key].forEach((err) => {
            input.insertAdjacentHTML(
              "afterend",
              `<div
                    class="validate__error"
                  >
                    ${err}
                  </div>`
            );
          });
        }

        setTimeout(() => {
          errorMessage.style.display = "";
        }, 5000);
        console.error("Ошибка при отправке!");
        // onFailSend();
        return false;
      } else {
        const thanksNode = document.querySelector(".contact__thanks");
        form.style.display = "none";
        thanksNode.style.display = "flex";
      }

      console.log("Отправлено");
      event.target.reset();
    } else if (selector === ".blog__form-inner") {
      const response = await fetch("https://ucliq.com/api/subscribe", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      formBtn.disabled = false;

      if (response.status < 199 || response.status > 300) {
        const errorMessage = form.querySelector(".modal__form-status--error");

        errorMessage.style.display = "block";

        // get answer from server
        const errorResponseObj = await response.json();

        const errors = errorResponseObj.violations.reduce((acc, err) => {
          if (acc.hasOwnProperty(err.propertyPath)) {
            acc[err.propertyPath] = [...acc[err.propertyPath], err.message];
          } else {
            acc[err.propertyPath] = [err.message];
          }

          return acc;
        }, {});

        for (const key in errors) {
          const input = form.querySelector(`[name='${key}']`);
          input.style.border = "1px solid rgb(184, 17, 17)";
          errors[key].forEach((err) => {
            input.insertAdjacentHTML(
              "afterend",
              `<div
                    class="validate__error"
                  >
                    ${err}
                  </div>`
            );
          });
        }

        setTimeout(() => {
          errorMessage.style.display = "";
          formBtn.disabled = false;
        }, 5000);
        console.error("Ошибка при отправке!");
        // onFailSend();
        return false;
      } else {
        const thanksNode = document.querySelector(".contact__thanks");
        document.querySelector(".blog__form").style.display = "none";
        thanksNode.style.display = "flex";
      }

      console.log("Отправлено");
      event.target.reset();
    }
  });

  validation.onFail((fields) => {
    console.log("fields", fields);
  });
};
